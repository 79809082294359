import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '../views/LandingPage.vue'
import PresentationPage from '@/views/PresentationPage.vue'
import ReglementPage from '@/views/ReglementPage.vue'
import NouvellesPage from '@/views/NouvellesPage.vue'
import ParticiperPage from '@/views/ParticiperPage.vue'
import ContactPage from '@/views/ContactPage.vue'
import MentionsLegalesPage from '@/views/MentionsLegalesPage.vue'
import AProposPage from '@/views/AProposPage.vue'
import CalendrierPage from '@/views/CalendrierPage.vue'
import ActuPage from '@/views/ActuPage.vue'

const routes = [
  {
    path: '/',
  name: 'Accueil',
    component: LandingPage
  },
  {
    path: '/presentation',
    name: 'Présentation',
    component: PresentationPage
  },
  {
    path: '/reglement',
    name: 'Règlement',
    component: ReglementPage
  },
  {
    path: '/nouvelles',
    name: 'Nouvelles',
    component: NouvellesPage
  },
  {
    path: '/participer',
    name: 'Participer',
    component: ParticiperPage
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactPage
  },
  {
    path: '/mentionlegale',
    name: 'Mentions légales',
    component: MentionsLegalesPage
  },
  {
    path: '/apropos',
    name: 'A propos',
    component: AProposPage
  },
  {
    path: '/calendrier',
    name: 'Calendrier',
    component: CalendrierPage
  },
  {
    path: '/actu',
    name: 'Actualités',
    component: ActuPage
  }



]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
