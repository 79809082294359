<template>
    <div class="contactpage">
        <NavBar />
        <BannerCompo />
        <div class="containercontact">
            <h1 class="titrepage">Nous contacter :</h1>
            <div class="columns">
                <div class="column is-half">
                    <h2 class="titresectioncont">Jocelyne Bour - Présidente de l'association</h2>
                    <p>Par mail :<a href="mailto:associationpassionnouvelle@gmail.com" class="inline"
                        > associationpassionnouvelle@gmail.com</a></p>
                </div>
                <div class="column is-half">
                    <h2 class="titresectioncont">Lucas Maujean - Responsable du site</h2>
                    <p>Par mail :<a href="lucas.maujean@gmail.com" class="inline">
                            lucas.maujean@gmail.com</a></p>
                </div>
            </div>
            <h1 class="titrepage">Une question ? Une remarque ?</h1>
            <h3 class="titresectioncont" style="margin-left:0.5em;">Nous contacter directement via ce
                site :</h3>
            <form @submit.prevent="submitContactForm" style="max-width: 60%;">
                <div class="field"><label class="titresoussection">Nom</label>
                    <div class="control"><input v-model="formData.lastName" class="input" type="text"
                            placeholder="Dupond" required></div>
                </div>
                <div class="field"><label class="titresoussection">Prénom</label>
                    <div class="control"><input v-model="formData.firstName" class="input" type="text"
                            placeholder="Antoine" required></div>
                </div>
                <div class="field"><label class="titresoussection">Email</label>
                    <div class="control"><input v-model="formData.email" class="input" type="email"
                            placeholder="antoinedupond@mail.com" required></div>
                </div>
                <div class="field"><label class="titresoussection">Votre
                        message</label>
                    <div class="control"><input v-model="formData.message" type="text" class="input"
                            placeholder="votre message" required></div>
                </div>
                <div class="field is-grouped">
                    <div class="control"><button id="button" type="submit">Envoyer</button>
                    </div>
                </div>
            </form>
            <div v-if="isLoading" class="loader"></div> <!-- Loader element -->
        </div>
        <FooterCompo />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooterCompo from '@/components/FooterCompo.vue';
import BannerCompo from '@/components/BannerCompo.vue';

export default {
    name: 'ContactPage',
    components: {
        NavBar,
        FooterCompo,
        BannerCompo
    },
    data() {
        return {
            formData: {
                firstName: '',
                lastName: '',
                email: '',
                message: ''
            },
            isLoading: false
        };
    },
    methods: {
        async submitContactForm() {
            this.isLoading = true; // Afficher le loader
            const formData = new FormData();
            formData.append('firstName', this.formData.firstName);
            formData.append('lastName', this.formData.lastName);
            formData.append('email', this.formData.email);
            formData.append('message', this.formData.message);
    
            try {
                const response = await fetch('https://tasteful-bramble-slicer.glitch.me/submitmessage', {
                    method: 'POST',
                    body: formData
                });
                const result = await response.text();
                setTimeout(() => {
                    this.isLoading = false; // Cacher le loader après quelques secondes
                    alert('Votre message a bien été envoyé !');
                    this.resetForm(); // Réinitialiser le formulaire
                }, 1000);
            } catch (error) {
                console.error('Error:', error);
                this.isLoading = false;
            }
        },
        resetForm() {
            this.formData = {
                firstName: '',
                lastName: '',
                email: '',
                message: ''
            };
        }
    }
};
</script>

<style scoped>
.contactpage {
    background-color: white;
}

.containercontact {
    margin: 2em;
}

.inline {
    color: #319093;
}   

.titrepage {
  font-family: Amatic SC,serif;
  font-size: 3em;
  font-weight: bold;
}

.titresectioncont {
  font-family: Dancing Script,cursive;
  font-size: 2em;
  color: grey;
}

.titresoussection {
  font-family: Dancing Script,cursive;
  font-size: 1.75em;
  color: #319093;
}

#button {
  background-color: #319093;
  color: #fff;
  padding: .5em 1em;
  border-radius: 5px;
  font-size: 1.25em;
  font-family: Dancing Script,cursive;
  text-decoration: none;
}

.field {
    margin-left: 1.5em;
}

/* Loader styles */
.loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #319093; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    margin: 20px auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>