<template>
    <div class="mentionslegales">
        <NavBar />
        <BannerCompo />
        <div class="containermention">
            <h1 class="titrepagepart">Mentions Légales : </h1>
            <h2 class="titresectionpart">1 - Editeur : </h2>
            <p>Association Passion Nouvelle <br>20 rue de l'église 57340 MORHANGE
                <br> 89892784300026 </p>
            <h2 class="titresectionpart">2 - Directeur de traitement et publication : </h2>
            <p>Directeur de publication : Madame Jocelyne Bour <br> Responsable de
                traitement : Monsieur Lucas Maujean</p>
            <h2 class="titresectionpart">3 - Hébergeur du site : </h2>
            <p>OVH <br> Siège Social 2 rue Kellermann, 59 100 Roubaix <br
                > Tél : 08 203 203 63 / n° indigo (0,118 €/min)</p>
            <h2 class="titresectionpart">4 - Propriété intellectuelle : </h2>
            <p> L'image utilisée pour la bannière d'illustration appartient à <a
                    href="https://unsplash.com/fr/@pickawood" class="inline">Pikawood</a> et a été
                obtenue sur <a href="https://unsplash.com/fr" class="inline">Unsplash</a>. Le reste
                du contenu de ce site (logiciel, logo, élément graphique...) est la propriété intellectuelle de
                PassionNouvelle. La réutilisation ou reproduction totale ou partielle des éléments du site quelque soit
                le moyen, est interdite sans l'accord écrit préable de l'association. <br> Toute
                exploitation non autorisée du site ou de l'un des éléments qu'il contient sera considérée comme
                constitutive d'une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et
                suivants du Code de Propriété Intellectuelle.</p>
            <h2 class="titresectionpart">5 - Informatique et liberté : </h2>
            <p>Pour nous envoyer votre nouvelle, merci de renseigner votre nom, votre prénom et votre
                adresse mail. Ces informations personnelles seront uniquement utilisées pour l'enregistrement de votre
                candidature et ne seront ni sauvegardées ni partagées.</p>
        </div>
        <FooterCompo />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooterCompo from '@/components/FooterCompo.vue';
import BannerCompo from '@/components/BannerCompo.vue';
export default {
    name: 'MentionsLegalesPage',
    components: {
        NavBar,
        FooterCompo,
        BannerCompo
    }

}
</script>

<style scoped>
.mentionslegales {
    background-color: white;
}

.containermention {
    padding: 2em;
}

.titrepagepart {
  margin-top: .5em;
  font-family: Amatic SC,cursive;
  font-size: 3em;
  font-weight: bold;
}

.titresectionpart {
  font-family: Dancing Script,cursive;
  font-size: 2.5em;
  margin-top: 1em;
}

.inline {
  color: #319093;
}


</style>