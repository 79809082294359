<template>
    <div class="banner">
      <div class="content">
        <img src="/logo.png" alt="Logo" class="logoban">
        <h1 class="textbanner">Bienvenue sur la page de Passion Nouvelle !</h1>
      </div>
    </div>
</template>

<script>
export default {
    name: 'BannerCompo',
};
</script>

<style scoped>
.banner {
    background-image: url(/public/head.jpg);
  background-size: cover;
  background-position: 50%;
  height: 54vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1em;
  }

  .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoban {
  margin-top: 1em;
}      

.textbanner {
  font-family: Roboto Slab,serif;
  font-size: 3.5em;
  color: #319093;
  font-weight: 10000;
  display: flex;
  text-align: center;
}

@media (max-width: 1180px) {
  .textbanner {
    display: none;
  }
}
</style>