<template>
    <div class="carousel">
        <div class="carousel-image">
            <div class="contentcarouse" style="display: flex; justify-content: center;">
                <figure style="max-width: 95%;">
                    <img :src="images[currentIndex].src" :alt="images[currentIndex].name" class="image-carousel">
                </figure>
            </div>
            <div class="image-name">{{ images[currentIndex].name }}</div>
        </div>
        <div class="tourbouton">
            <button class="button" @click="prevImage">Diapo précédente</button>
            <button class="button" @click="nextImage">Diapo suivante</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentIndex: 0,
            images: [
                { src: '/carousel1.JPG', name: 'Remise du prix 2022/2023' },
                { src: 'carousel2.JPG', name: 'Remise du prix 2022/2023' },
                { src: 'carousel3.JPG', name: 'Remise du prix 2022/2023'}
            ]
        };
    },
    methods: {
        prevImage() {
            this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
        },
        nextImage() {
            this.currentIndex = (this.currentIndex + 1) % this.images.length;
        }
    }
};
</script>

<style scoped>
.carousel {
    text-align: center;
}

.carousel-image {
    position: relative;
    display: inline-block;
}

.image-name {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    background-color: rgba(0, 0, 0, .5);
    padding: 10px;
    font-family: Dancing Script, cursive;
    font-size: 2em;
    font-weight: bold;
    margin-left: 3em

}

.carousel-controls {
    margin-top: 10px;
}

.image-carousel {
    max-width: 100%;
    border-radius: 3%;
}

.tourbouton {
    display: flex;
    justify-content: space-around;
    margin-top: 1em;
    margin-bottom: 1em;
}

.button {
    background-color: #319093;
    color: #fff;
    padding: .5em 1em;
    border-radius: 5px;
    font-size: 1.25em;
    font-family: Dancing Script, cursive;
    text-decoration: none;
}
</style>