<template>
    <nav class="navbar">
        <div class="navbar-brand">
            <a role="button" class="navbar-burger" :class="{'is-active': isActive}" @click="toggleMenu" aria-label="menu" aria-expanded="false">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>
        <div class="navbar-menu" :class="{'is-active': isActive}">
            <div class="navbar-start">
                <a href="/presentation" class="navbar-item"> Présentation </a>
                <a href="/reglement" class="navbar-item"> Règlement </a>
                <a href="/" class="logonavbar"> Passion Nouvelle </a>
                <a href="/nouvelles" class="navbar-item"> Nouvelles </a>
                <a href="/participer" class="navbar-item"> Participer </a>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'NavBar',
    data() {
        return {
            isActive: false
        };
    },
    methods: {
        toggleMenu() {
            this.isActive = !this.isActive;
        }
    }
};
</script>

<style scoped>
.navbar-burger {
    display: none;
}

.navbar-burger span {
    background-color: white;
}

.navbar-start{
    display: flex;
    justify-content: space-around;
    width: 100%;
}

@media (max-width: 1020px) {
    .navbar-burger {
        display: block;
    }
    .navbar-menu {
        display: none;
        flex-direction: column;
    }
    .navbar-menu.is-active {
        background-color: #319093;
        padding-left: 1em;
    }
    .navbar-menu.is-active .navbar-start {
        display: flex;
        flex-direction: column;
    }
}

.navbar {
    background-color: #319093;
}

.logonavbar{
    font-family: 'Amatic SC', cursive;
    font-size: 4em;
    font-weight: bold;
    color: white;
}

.navbar-item {
    font-family: 'Dancing Script', cursive;
    font-size: 3em;
    color: white;
}

.navbar-item:hover {
    color: blue;
}
</style>