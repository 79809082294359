<template>
    <div class="landingpage">
      <NavBar/>
    <BannerCompo/>
    <div class="attention">
      <h2 class="texteattention">Au vu du nombre important de nouvelles reçues, nous avons pris la décision de reporter la remise du prix littéraire Odette Massfelder prévue début décembre 2024, au 10 janvier 2025. Merci de votre compréhension.</h2>
    </div>
    <div class="containnerac">
      <h1 class="titrepage">Bienvenue sur la page d'accueil du site </h1>
      <div class="sectionacc">
        <h3 class="titresection">Le prix en quelques mots :</h3>
        <p> Le prix Odette Massfelder est un prix littéraire organisé par l'association <b data-v-1aa2e71c="">Passion Nouvelle</b>. Le prix est ouvert à tous, sans distinction d’âge, de nationalité ou de résidence. Les participants écrivent une nouvelle dont le sujet est libre. Le consensus du comité de lecture honore et récompence le lauréat et la mention de prix...</p>
        <div class="tourbouton">
          <a href="/presentation" class="button">En savoir plus</a>
        </div>
      </div>
      <div class="sectionacc">
        <h3 class="titresection">Extrait de Règlement :</h3>
        <h5 class="soustitresect">Article 3 : Présentation des textes :</h5>
        <p>Il s’agit pour les candidats de présenter conformément au présent règlement une nouvelle ; Le choix du sujet est libre ; Chaque texte présenté, sera rédigé en français, dactylographié en police de 12 avec un interligne simple, il ne devra pas excéder six pages, être anonyme et sera expédié en trois exemplaires ; Les pages devront être numérotées ; Le texte aura toujours un titre ; Dans une enveloppe devront être portées les coordonnées de l’auteur(e) (nom, adresse, téléphone, adresse mail et titre de la nouvelle) ; Les insertions de photos dans les textes sont proscrites.</p>
        <div class="tourbouton">
          <a href="/reglement" class="button">En voir plus</a>
        </div>
      </div>
      <h2 class="titrepage">Nous découvrir :</h2>
      <CarouselCompo/>
      <h2 class="titrepage">Intérresé(e) ?</h2>
      <div class="sectionacc2">
        <div class="columns">
          <div class="column" style="display: flex; align-items: center;">
            <p>Vous voulez participer au prix littéraire ? Envoyez votre nouvelle directement depuis ce site en vous rendant sur la page participer.</p>
          </div>
          <div class="column is-one-quarter">
            <div class="tourbouton">
              <a  href="/participer" class="button">S'y rendre</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterCompo/>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import BannerCompo from '@/components/BannerCompo.vue';
import CarouselCompo from '@/components/CarouselCompo.vue';
import FooterCompo from '@/components/FooterCompo.vue';
export default {
    name: 'LandingPage',
    components: {
      NavBar,
      BannerCompo,
      CarouselCompo,
      FooterCompo
    },
    data() {
    return {
      wakeupMessage: ''
    };
  },
  methods: {
    async wakeupServer() {
      try {
        const response = await fetch('https://tasteful-bramble-slicer.glitch.me/wakeup');
        const message = await response.text();
        this.wakeupMessage = message;
        console.log(message);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  },
  mounted() {
    this.wakeupServer();
  }
};
</script>

<style scoped>
.attention {
background-color: #319093;
  min-height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1em;
}

.landingpage{
  background-color: #fff;
}
.texteattention {
  color: #fff;
  text-align: center;
  font-size: 1em;
  font-family: Roboto Slab,serif;
  font-weight: bold;
}

.containnerac {
  margin-left: 1em;
  background-color: white;
}

.titrepage{
  margin-top: .5em;
  font-family: Amatic SC,cursive;
  font-size: 3em;
  margin-bottom: .5em;
  margin-left: .5em;
  font-weight: bold;
}

.sectionacc{
  display: inline-block;
  border: 3px solid #333;
  border-radius: 2% 6% 5% 4%/1% 1% 2% 4%;
  background: #fff;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 2em;
  margin-bottom: 1em;
  width: 95vw;
}

.sectionacc2{
  display: inline-block;
  border: 3px solid #333;
  border-radius: 2% 6% 5% 4%/1% 1% 2% 4%;
  background: #fff;
  padding-left: 2em;
  padding-right: 2em;
  margin-bottom: 1em;
  width: 95vw;
  padding-top: 1em 
}

.titresection{
  font-family: Dancing Script,cursive;
  font-size: 2em;
  color: #319093;
  width: 80vw;
}

.soustitresect{
  font-family: Amatic SC,cursive;
  font-size: 1.75em;
  font-weight: bold;
}

.tourbouton{
  display: flex;
  justify-content: end;
  margin-top: 1em;
  margin-bottom: 1em;
}

.button {
  background-color: #319093;
  color: #fff;
  padding: .5em 1em;
  border-radius: 5px;
  font-size: 1.25em;
  font-family: Dancing Script,cursive;
  text-decoration: none;
}

</style>