<template>
   <footer class="footer has-text-black">
        <div class="tourseparator">
            <div class="separator"></div>
        </div>
        <div class="content">
            <div class="hautfooter">
                <a href="/contact" class="lienfooter">Contact</a>
                <a href="/mentionlegale" class="lienfooter">Mentions Légales</a>
                <a href="/apropos" class="lienfooter">À propos</a>
                <a href="/calendrier" class="lienfooter">Calendrier</a>
                <a href="/actu" class="lienfooter">Actu</a>
                <a href="https://www.facebook.com/odette.prix/" target="_blank">
                    <img class="facebook-logo" src="/logo-facebook.png" alt="Facebook">
                </a>
            </div>
            <div class="basfooter">
                <img src="/logo.png" class="logo" alt="App Logo">
                <p class="copyright"> © 2022 Association Passion Nouvelle</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterCompo'
}
</script>

<style scoped>

@media (max-width: 900px) {
    .hautfooter {
        flex-direction: column;
    }
}

.footer {
    background-color: white;
}

.tourseparator {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.separator {
    width: 100vw;
    border: 1px solid #d3d3d3;
}

.hautfooter {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
}

.lienfooter {
    color: #000;
    font-size: 2em;
    font-weight: 700;
    font-family: Dancing Script, cursive;
}

.facebook-logo {
    margin-top: 0.5em;
    width: 2em;
    height: auto;
}

.basfooter {
    display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5em;
}

.logo {
    width: 5em;
    height: auto;
}

.copyright{
  color: #000;
  font-size: 1.5em;
  font-family: Dancing Script,cursive;
}
</style>