<template>
    <div class="presentationpage">
        <NavBar />
        <BannerCompo />
        <div class="presentationcontainer">
            <h1 class="titrepage">Présentation du prix : </h1>
            <p>Ce prix fut créé en 2005 pour la commune de Morhange. Deux conseillères municipales : Mireille RAPENNE et
                Jocelyne BOUR, furent nommées pour mettre en place un projet de grande envergure. Elles ne mesuraient
                pas
                l'ampleur du défi demandé, il leur fallait concevoir un règlement, composer un comité qui regroupe des
                lecteurs et lectrices qui allaient partager leurs avis sur le fond et la forme des textes reçus, pour
                enfin
                honorer et récompenser les auteurs pour la qualité de leurs écrits. <br>
                Pourquoi le prix littéraire Odette Massfelder ? Elle fut la présidente de l’association « Les amis du
                livre
                ». Cette association Morhangeoise a créé une bibliothèque, et a inspiré à la commune une nouvelle
                dynamique
                culturelle. Le nom de madame Odette Massfelder devait être associée à ce prix littéraire. <br>
                Aujourd'hui, ce prix est porté par l'association <b>PASSION NOUVELLE</b>, et son comité de lecture, mais
                demeure le prix littéraire Odette Massfelder.<br>
                PASSION NOUVELLE - Présidente Jocelyne BOUR
            </p>
            <h2 class="titresection">Remerciements à tous nos auteur(e)s : </h2>
            <p>Comme vous le savez, le compliment littéraire n’est pas une pratique aisée, alors pour commencer, je
                souhaite
                dire à tous les participants à ce prix, que tous les textes qui nous sont envoyés, sont d’une grande
                beauté
                et méritent d’être distingués. Tous les auteurs ont le pouvoir de nous émouvoir, de nous faire vibrer et
                de
                nous inspirer. Tous aiguisent l’intérêt et la curiosité. Mais il faut faire un choix ! Voilà sans doute
                l’aspect le plus délicat du travail des jurés. Le consensus est alors juge et décideur des heureux
                lauréats.
                <br> Je remercie le comité de lecture, pour son implication dans l’aventure PASSION NOUVELLE, votre
                temps,
                votre énergie et votre engagement sans faille sont très précieux. Je veux toujours me rappeler que le
                bénévolat et l’engagement associatif sont avant tout une histoire humaine. Je souhaite par ces quelques
                mots, vous faire partager la passion qui nous anime.
            </p>
            <h2 class="titresection">Intérresé(e) ? </h2>
            <div class="columns">
                <div class="column" style="display: flex; align-items: center;">
                    <p>Vous voulez participer au prix littéraire ? Envoyez votre nouvelle directement depuis ce site en
                        vous
                        rendant sur la page participer.</p>
                </div>
                <div class="column is-one-quarter">
                    <div class="tourbouton">
                        <a href="/participer" class="button">S'y rendre</a>
                    </div>
                </div>
            </div>
        </div>
        <FooterCompo />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooterCompo from '@/components/FooterCompo.vue';
import BannerCompo from '@/components/BannerCompo.vue';
export default {
    name: 'PresentationPage',
    components: {
        NavBar,
        FooterCompo,
        BannerCompo
    },
};
</script>

<style scoped>
.presentationpage {
    background-color: white;
}

.presentationcontainer {
    padding-left: 1em;
    padding-right: 1em;
    background-color: white;
}

.titrepage {
    font-family: Dancing Script, cursive;
    font-size: 2em;
    color: #319093;
    width: 80vw;
    margin-top: 1em;
}

.titresection {
    font-family: Dancing Script, cursive;
    font-size: 2em;
    color: #319093;
    width: 80vw;
    margin-top: 1em;
}

.tourbouton {
    display: flex;
    justify-content: end;
    margin-top: 1em;
    margin-bottom: 1em;
}

.button {
    background-color: #319093;
    color: #fff;
    padding: .5em 1em;
    border-radius: 5px;
    font-size: 1.25em;
    font-family: Dancing Script, cursive;
    text-decoration: none;
}
</style>